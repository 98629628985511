import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Teaching Statement" />

    <section className="pb-10">
      <h1 className="text-3xl lg:text-4xl text-center pb-6">
        Teaching Statement
      </h1>
      <p className="bg-yellow-light-a30 border border-gray-light rounded shadow max-w-3xl mx-auto text-justify leading-loose p-4">
        I believe every child can and should learn music just like they learn
        math, language or any other subject. My goal is to provide an enjoyable
        and substantive experience so students can develop their own love for
        music. It is important to create a feeling of ease and rapport lessons.
        Each student has his or her unique qualities, therefore my greatest
        responsibility is to recognize their individuality and teach them
        accordingly. I always take a professional approach when dealing with
        students` musical and academical goals. I guide them through piano
        lessons so they can progress at their best pace. It is such a pleasure
        to train and mentor many talented students from countries all over the
        world and I am humbled and grateful to have this opportunity. I feel
        that teaching is my moral obligation to pass on what was given to me.
      </p>
    </section>
  </Layout>
)

export default IndexPage
